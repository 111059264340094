"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.formatter = exports.FormatTypes = void 0;
const dateUtils_1 = require("./dateUtils");
const truncate_1 = require("./truncate");
var FormatTypes;
(function (FormatTypes) {
    FormatTypes["MONEY"] = "money";
    FormatTypes["PERCENTAGE"] = "percentage";
    FormatTypes["DECIMAL_PERCENTAGE"] = "decimalPercentage";
    FormatTypes["DATE"] = "date";
    FormatTypes["DATE_LOCAL"] = "dateLocal";
    FormatTypes["DECIMAL"] = "decimal";
    FormatTypes["NUMBER"] = "number";
    FormatTypes["DAYS"] = "days";
    FormatTypes["MONTHS"] = "months";
})(FormatTypes = exports.FormatTypes || (exports.FormatTypes = {}));
const formatter = (data, format) => {
    if (!data && format === FormatTypes.MONEY)
        return '$0.00';
    if (data == null)
        return 'N/A';
    const stringData = data.toString();
    switch (format) {
        case FormatTypes.MONEY: {
            const parsedMoney = parseFloat(stringData);
            return !parsedMoney
                ? 'N/A'
                : new Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency: 'USD',
                }).format((0, truncate_1.truncate)(parsedMoney, 100000));
        }
        case FormatTypes.PERCENTAGE:
            return `${Math.round(parseFloat(stringData))}%`;
        case FormatTypes.DECIMAL_PERCENTAGE:
            return `${parseInt(stringData, 10).toFixed(2)}%`;
        case FormatTypes.NUMBER:
            return `${parseInt(stringData, 10)}`;
        case FormatTypes.DATE:
            return (0, dateUtils_1.toLocalTime)(stringData).toLocaleDateString('en-us');
        case FormatTypes.DATE_LOCAL:
            return new Date(stringData).toLocaleDateString('en-us');
        case FormatTypes.DECIMAL: {
            const parsedDecimal = parseFloat(stringData);
            return !parsedDecimal ? 'N/A' : parsedDecimal.toFixed(2);
        }
        case FormatTypes.DAYS:
            return `${stringData} days`;
        case FormatTypes.MONTHS:
            return `${stringData} months`;
        default:
            return 'N/A';
    }
};
exports.formatter = formatter;
