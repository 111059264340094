"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.YearWeek = exports.getWeekOfYear = exports.getWeekNumber = exports.formatDateRange = exports.YearMonth = exports.YearQuarter = exports.DateRange = void 0;
class DateRange {
    constructor(startDate, endDate) {
        this.StartDate = startDate;
        this.EndDate = endDate;
    }
    get DaysInBetween() {
        return Math.round(Math.abs(this.EndDate.getTime() - this.StartDate.getTime()) / (1000 * 3600 * 24));
    }
    get Days() {
        const days = [];
        const startDate = new Date(this.StartDate);
        const endDate = new Date(this.EndDate);
        while (startDate <= endDate) {
            days.push(new Date(startDate));
            startDate.setDate(startDate.getDate() + 1);
        }
        return days;
    }
    toString() {
        return (0, exports.formatDateRange)(this.StartDate, this.EndDate);
    }
}
exports.DateRange = DateRange;
class YearQuarter extends DateRange {
    constructor(year, quarter) {
        year = year !== null && year !== void 0 ? year : new Date().getFullYear();
        quarter = quarter !== null && quarter !== void 0 ? quarter : YearQuarter.CurrentQuarter;
        const startDate = new Date(year, (quarter - 1) * 3, 1);
        const endDate = new Date(year, quarter * 3, 0);
        super(startDate, endDate);
        this.Year = year;
        this.Quarter = quarter;
    }
    static get Current() {
        return new YearQuarter();
    }
    static FromDate(date) {
        return new YearQuarter(date.getFullYear(), YearQuarter.CurrentQuarter);
    }
    static get CurrentQuarter() {
        return Math.floor(new Date().getMonth() / 3 + 1);
    }
    get IsCurrent() {
        return this.Year === new Date().getFullYear() && this.Quarter === YearQuarter.CurrentQuarter;
    }
    get Next() {
        const nextQuarter = this.Quarter + 1;
        const nextYear = this.Year + (nextQuarter > 4 ? 1 : 0);
        return new YearQuarter(nextYear, nextQuarter > 4 ? 1 : nextQuarter);
    }
    get Previous() {
        const previousQuarter = this.Quarter - 1;
        const previousYear = this.Year - (previousQuarter < 1 ? 1 : 0);
        return new YearQuarter(previousYear, previousQuarter < 1 ? 4 : previousQuarter);
    }
    toString() {
        return `${this.Year}-Q${this.Quarter}`;
    }
}
exports.YearQuarter = YearQuarter;
class YearMonth extends DateRange {
    constructor(year, month) {
        year = year !== null && year !== void 0 ? year : new Date().getFullYear();
        month = month !== null && month !== void 0 ? month : new Date().getMonth() + 1;
        const startDate = new Date(year, month - 1, 1);
        const endDate = new Date(year, month, 0);
        super(startDate, endDate);
        this.Year = year;
        this.Month = month;
    }
    static get Current() {
        return new YearMonth();
    }
    static FromDate(date) {
        return new YearMonth(date.getFullYear(), date.getMonth() + 1);
    }
    get IsCurrent() {
        return this.Year === new Date().getFullYear() && this.Month === new Date().getMonth() + 1;
    }
    get Next() {
        const nextMonth = this.Month + 1;
        const nextYear = this.Year + (nextMonth > 12 ? 1 : 0);
        return new YearMonth(nextYear, nextMonth > 12 ? 1 : nextMonth);
    }
    get Previous() {
        const previousMonth = this.Month - 1;
        const previousYear = this.Year - (previousMonth < 1 ? 1 : 0);
        return new YearMonth(previousYear, previousMonth < 1 ? 12 : previousMonth);
    }
    toString() {
        return `${this.Year}-${this.Month.toString().padStart(2, '0')}`;
    }
}
exports.YearMonth = YearMonth;
const dateOptions = { month: 'numeric', day: 'numeric', year: 'numeric' };
const formatDateRange = (start, end) => `[${start.toLocaleDateString('en-US', dateOptions)} - ${end.toLocaleDateString('en-US', dateOptions)}]`;
exports.formatDateRange = formatDateRange;
const getWeekNumber = (date) => {
    const onejan = new Date(date.getFullYear(), 0, 1);
    return Math.ceil(((date - +onejan) / 86400000 + onejan.getDay() + 1) / 7);
};
exports.getWeekNumber = getWeekNumber;
const getWeekOfYear = () => (0, exports.getWeekNumber)(new Date());
exports.getWeekOfYear = getWeekOfYear;
class YearWeek extends DateRange {
    constructor(year, week) {
        year = year !== null && year !== void 0 ? year : new Date().getFullYear();
        week = week !== null && week !== void 0 ? week : (0, exports.getWeekOfYear)();
        const startDate = new Date(year, 0, 1 + (week - 1) * 7);
        const endDate = new Date(year, 0, 7 + (week - 1) * 7);
        super(startDate, endDate);
        this.Year = year;
        this.Week = week;
    }
    static get Current() {
        return new YearWeek();
    }
    static FromDate(date) {
        return new YearWeek(date.getFullYear(), (0, exports.getWeekNumber)(date));
    }
    get Next() {
        const nextWeek = this.Week + 1;
        const nextYear = this.Year + (nextWeek > 52 ? 1 : 0);
        return new YearWeek(nextYear, nextWeek > 52 ? 1 : nextWeek);
    }
    get Previous() {
        const previousWeek = this.Week - 1;
        const previousYear = this.Year - (previousWeek < 1 ? 1 : 0);
        return new YearWeek(previousYear, previousWeek < 1 ? 52 : previousWeek);
    }
    toString() {
        return `${this.Year}-W${this.Week.toString().padStart(2, '0')}`;
    }
}
exports.YearWeek = YearWeek;
