"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getAbortController = exports.setAbortController = void 0;
let abortController;
const setAbortController = (ac) => {
    abortController = ac;
};
exports.setAbortController = setAbortController;
const getAbortController = () => abortController || new AbortController();
exports.getAbortController = getAbortController;
