"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.roundDown = exports.truncateToZero = exports.truncateToOne = exports.truncate = void 0;
const truncate = (number, exp = 100) => Math[number < 0 ? 'ceil' : 'floor'](number * exp) / exp;
exports.truncate = truncate;
const truncateToOne = (number) => (0, exports.truncate)(number, 10);
exports.truncateToOne = truncateToOne;
const truncateToZero = (number) => (0, exports.truncate)(number, 1);
exports.truncateToZero = truncateToZero;
const roundDown = (value) => {
    const rounded = Math.round(value * 100) / 100;
    const truncated = (0, exports.truncate)(value);
    return Math.abs(value - rounded) < Math.abs(value - truncated) ? rounded : truncated;
};
exports.roundDown = roundDown;
