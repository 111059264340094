"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.sumBy = exports.sumByName = exports.selectNumberBy = exports.selectNumberByName = exports.identity = exports.nameof = void 0;
const nameof = (name) => name;
exports.nameof = nameof;
const identity = (x) => x;
exports.identity = identity;
const selectNumberByName = (data, name) => data.map((i) => Number(i[name]));
exports.selectNumberByName = selectNumberByName;
const selectNumberBy = (data, selector) => data.map(selector).map((x) => Number(x));
exports.selectNumberBy = selectNumberBy;
const sumByName = (data, name, callBack) => {
    const innerCallback = callBack || exports.identity;
    return (0, exports.selectNumberByName)(data, name).reduce((acc, item) => innerCallback(acc + item), 0);
};
exports.sumByName = sumByName;
const sumBy = (data, selector, callBack) => {
    const innerCallback = callBack || exports.identity;
    return (0, exports.selectNumberBy)(data, selector).reduce((acc, item) => innerCallback(acc + item), 0);
};
exports.sumBy = sumBy;
